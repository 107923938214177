<template>
  <div>
    <h1 class="mb-4">{{ typeLabel }} #{{ apiDataGet.doc_number }} bewerken</h1>
    <span v-if="loading">Loading ...</span>
    <div v-else>
      <FormCreditNote v-if="type === 'CREDIT_NOTE'" @submit="onSubmit" :disabled="api.loading.value" v-model="info" />
      <FormFactuur v-else-if="type === 'INVOICE'" @submit="onSubmit" :disabled="api.loading.value" v-model="info" />
    </div>
  </div>
</template>

<script setup>
import FormFactuur from '@/components/Form/Facturatie/Factuur.vue'
import FormCreditNote from '@/components/Form/Facturatie/CreditNote.vue'
import useApi from '@/hooks/useApi'
import useGetApi from '@/hooks/useGetApi'
// import useSearchItem from '@/hooks/useSearchItem'
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const props = defineProps({
  type: {
    type: String,
    default: 'INVOICE',
  },
  typeLabel: {
    type: String,
    default: 'Factuur',
  },
})

const route = useRoute()
const router = useRouter()

const apiDataGet = computed(() => ({
  type: props.type,
  journal_id: String(route.query.journal_id || ''),
  doc_number: String(route.params.doc_number || ''),
}))

const api = useApi()
// const searchItem = useSearchItem()

const { loading, data: info } = useGetApi('FINANCIAL_DOCS_INFO', apiDataGet, { watch: true })

watch(loading, (val) => {
  if (val) return
  info.value.doc_number = apiDataGet.value.doc_number
  info.value.journal_id = apiDataGet.value.journal_id
  info.value.products = info.value.products || []

  const ritten = route.query.ritten?.split(',')
    .filter(rit_id => rit_id > 0 && !info.value.products.find(p => p.rit_id === rit_id))
    .map(rit_id => ({ rit_id, rit_heen: true, rit_retour: true, force: false, negeer_wachttijden: false }))

  if (ritten?.length) {
    info.value.products = [].concat(info.value.products, ritten)
  }

  if (info.value.client) {
    // searchItem.value = info.value.client
    // info.value.client_id = info.value.client.id || null
  }
})

const onSubmit = data => {
  data.type = apiDataGet.value.type
  data.journal_id = apiDataGet.value.journal_id
  api('FINANCIAL_DOCS_EDIT', data).then(() => {
    router.push({ path: route.path.split('/bewerken')[0], query: { journal_id: data.journal_id } })
  })
}
</script>
